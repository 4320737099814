import React from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';



//props.actionHandler()
//onClick={}

const Pay = (props) => {


  return (
    <div className={classes.Info}>

      <div className={classes.boxHigh}>
        <p> available: <b>{props.balances ? props.balances[3] : "n/a" }</b> µETH</p>
        <p> available: <b>{props.balances ? props.balances[3] / 1000000 : "n/a" }</b> ETH</p>
      </div>

      <button
        className={classes.digButton}
        onClick={() => {
          props.actionHandler();
        }}
        >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>Cash Out</p>
      </button>

      <p><br/></p>
      <div className={classes.box}>
      <p>Cash your earnings out.</p>
      <div><p>
        You decide when it is payday. Cash out in Ethereum direct to your Wallet.
        <br/>
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};

export default Pay;
