
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

/*
import base64 from 'react-native-base64'
import md5 from 'react-native-md5'
*/

//import { AiOutlineProfile, AiOutlineFileImage, AiOutlineVideoCamera } from "react-icons/ai";

//import { IconContext } from "react-icons";



// styles
import classes from './App.module.css';


// abis now
import ROCK from '../src/abis/ROCK.json' ;

// components
import Navigation from './components/Navigation';

import Balance from './components/Balance';
import Block  from './components/Block';
import UnBlock from './components/UnBlock';
import Dig  from './components/Dig';
import Pay  from './components/Pay';
import Info from './components/Info';

import Origin from './components/Origin';

//import AdminTesting from './components/AdminTesting';

import icon from './assets/icon.png';
import icon2 from './assets/icon2.png';



//const ROCK = ROCK2;




const App = ( props ) => {

  console.log("app props",props);

  const chainId = 4;

  const [account, setAccount] = useState('Connecting to Metamask..');
  const [network, setNetwork] = useState({ id: '0', name: 'none' });
  const [gasPrice, setGasPrice] = useState(40);

  const [appStatus, setAppStatus] = useState(true);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const [rckTokenContract, setrckTokenContract] = useState('');
//  const [rckStatus, setrckStatus] = useState({ flags: [], dates: []});
//  const [rckFees, setrckFees] = useState({  denominator: '0',  royalties: [{ account: '0x0', value: '0'}],  market: { account: '0x0', value: '0' }  });

  const [rckAccess, setrckAccess] = useState('');
  const [rckPrice, setrckPrice] = useState('');

  const [rckFriendExists, setrckFriendExists] = useState(false);
  const [rckFriendReal, setrckFriendReal] = useState(false);

  const [rckContractInfo, setrckContractInfo] = useState({});
  const [rckContractBalances, setrckContractBalances] = useState([]);

  const [inputBlock, setInputBlock] = useState('');
  const [inputUnBlock, setInputUnBlock] = useState('');
  const [inputDig, setInputDig] = useState('');

  const [uriInvite, setUriInvite] = useState('');

  const [tickerEnabled, setTicketEnabled] = useState(false);

  const [rckSubs, setrckSubs] = useState({ childs: [], balances: [] });

  const [chainChangeCounter, setChainChangeCounter] = useState(0);

  const [userBalance, setUserBalance] = useState('0');



  const fee = ( 0.002 / 1000).toString();



  useEffect(() => {
    //connecting to ethereum blockchain
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };

    ethEnabled();
  }, []);


/*
window.ethereum.on('accountsChanged', function (accounts) {
  // Time to reload your interface with accounts[0]!
  console.log(accounts);

  fetchDataFromBlockchain();
});

window.ethereum.on('networkChanged', function (networkId) {
  // Time to reload your interface with the new networkId
  console.log(networkId);

  fetchDataFromBlockchain();
});

*/

  const setLivePebbles = ( f ) => {
    window.livePebbles = f;
  }


//  const getRandomStr = () => Math.random().toString(36).slice(2);


  const crpt = (sash, d) => {

      var m = [ [3,30], [5,9], [11,20] , [8,12]  ];
      var hash = sash.split('');

      if (hash[1]==='x') {
        console.log("##c#");
        for (var i=0; i<m.length; i++) {
          let t = "";
          if (d) {
            t = hash[m[i][0]];
            hash[m[i][0]] = hash[m[i][1]];
            hash[m[i][1]] = t;
          }
          else
          {
            t = hash[m[i][1]];
            hash[m[i][1]] = hash[m[i][0]];
            hash[m[i][0]] = t;
          }
        }
      }
      hash[1]="x";
      return hash.join('');
  }



  const uriParts = window.location.href.split("/");
  let friendAddress = crpt( uriParts.pop() ,false);

//uriParts[2] + (uriParts[-1]==='?' ? "" : '?')


/*
  const validAddress = (ethereumAddress) => {
      let regex = /^0x[0-9a-fA-F]{40}$/;
      if ( ethereumAddress.match(regex)) {
          console.log(ethereumAddress,"ok");

          return true;
      }
      console.log(ethereumAddress,"failed");
      return false;
  }
*/

const fetchSubsFromBlockchain = async ( props ) => {

  if (!appStatus) {
  } else {

    console.log("tree");

    setLoader(true);

    if ( window.ethereum ) {


      await window.ethereum.request({ method: 'eth_requestAccounts' });

      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();

      const rckTokenData = ROCK.networks[networkId];
      if (rckTokenData) {

        const rckToken = new web3.eth.Contract(
          ROCK.abi,
          rckTokenData.address
        );

        let e = await rckToken.methods
            .memberExists(accounts[0])
            .call();

        if (!e) return;


        let m = await rckToken.methods
            .getMember(accounts[0])
            .call();

        let b = [];

        m.childs.forEach( async (a) => {

          let mb = await rckToken.methods
              .balancesOf(a)
              .call();

          b.push(mb);
        });

        console.log("members",m,m.childs,b);
        setrckSubs({ childs: m.childs, balances: b});
      }
    }
    setLoader(false);
  }
}



  const fetchTickerSimulate = async ( props ) => {


    if (!window.livePebbles) {
      console.log("ticker pebbles stopped.");
      return;
    }

    if (props.tick / props.frame < 1){
      console.log("ticker pebbles stopped, no progress ",props);
      return;
    }

    console.log("ticker pebble", props);

    let p = {
      balances: [],
      tick: props.tick,
      counter: props.counter - 1,
      last: props.last,
      value: parseInt(props.value) + props.tick,
      frame: props.frame
    }

    let i = 0;
    while (i < props.balances.length) {
      p.balances[i] = props.balances[i];
      i++;
    }
    p.balances[2] = parseInt(p.value);

    setrckContractBalances(p.balances);

    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
    await waitFor(props.frame * 1000 );

    if (p.counter > 1)
      fetchTickerSimulate( p );
  }



  const fetchTickerFromBlockchain = async ( props ) => {

    if (!appStatus) {
    } else {

      let b=[];

      console.log("ticker pebbles fetch #### ",props);


      setTicketEnabled(true);

      if ( window.ethereum ) {


        await window.ethereum.request({ method: 'eth_requestAccounts' });

        //connecting to metamask
        window.web3 = new Web3(window.ethereum);
        let web3 = window.web3;

        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const gasPrice = await web3.eth.getGasPrice();

        const rckTokenData = ROCK.networks[networkId];
        if (rckTokenData) {

          const rckToken = new web3.eth.Contract(
            ROCK.abi,
            rckTokenData.address
          );

          b = await rckToken.methods
              .balancesOf(accounts[0])
              .call();

          setrckContractBalances(b);

        // LIVEFIX
        //  setGasPrice(gasPrice);



        }

        let t = props.first ? 15 : 60;


        if (props.last.length > 2) {

           let tick = (b[2] - props.last[2]) / props.lastTick ;

           if (!window.livePebbles) {
             setLivePebbles(true);
             console.log("ticket pebbles started.");

             fetchTickerSimulate( { counter: t*4, balances: b, tick:  tick/4, value: b[2], last: props.last, frame: 1/4 } );
           }
        }



        const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
        await waitFor(t * 1000);

        fetchTickerFromBlockchain( { last: b, first: false , lastTick: t} );
      }
   }

  }


  const changeNetwork = async ( props ) => {

   console.log("change of network requested cnt:",chainChangeCounter);

   setChainChangeCounter( chainChangeCounter + 1 );

   if (window.ethereum) {

     window.web3 = new Web3(window.ethereum);

     try {
       await window.ethereum.request({
       method: 'wallet_switchEthereumChain',
         params: [{ chainId: window.web3.utils.toHex(props.chainId) }],
       });

       //window.location.reload();


       fetchDataFromBlockchain();
/*
       if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))
        fetchDataFromBlockchain();
       else
        window.location.reload();
*/
     } catch (error) {
       console.error(error);
     }
   }
 }

  const fetchDataFromBlockchain = async () => {


    if ( window.ethereum ) {

      console.log("chain",window.ethereum.networkVersion, chainId);
      if (window.ethereum.networkVersion != chainId) {
        changeNetwork({ chainId: chainId });

        return;
      }


      console.log("eth checked");

      await window.ethereum.request({ method: 'eth_requestAccounts' });


      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      console.log(web3);


      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);


      //loading users network ID and name
      const networkId = await web3.eth.net.getId();
      const networkType = await web3.eth.net.getNetworkType();
      setNetwork({ ...network, id: networkId, name: networkType });

      console.log("x2");

//      const balanceEthereum = await web3.eth.getBalance(accounts[0]);

      const result = await web3.eth.getBalance(accounts[0]);
      console.log("balance:",web3.utils.fromWei(result, "ether") + " ETH");
      /*
      , function(err, result) {
        if (err) {
          console.log(err)
        } else {
          console.log(web3.utils.fromWei(result, "ether") + " ETH");
          setUserBalance(web3.utils.fromWei(result, "ether"));
        }
      });
      */
      setUserBalance(web3.utils.fromWei(result,"ether"));

      const rckTokenData = ROCK.networks[networkId];
      if (rckTokenData) {
        let web3 = window.web3;
        const rckToken = new web3.eth.Contract(
          ROCK.abi,
          rckTokenData.address
        );

        setrckTokenContract(rckToken);







        // TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON





          //  fetching balance of Testtoken and storing in state
          let contractInfo = {
              totals: await rckToken.methods.totalSupply().call(),
              decimals: await rckToken.methods.decimals().call(),
              symbol: await rckToken.methods.symbol().call(),
              name: await rckToken.methods.name().call(),
              totalsBlocked: await rckToken.methods.totalSupplyBlocked().call(),
              totalEther:    await rckToken.methods.totalEther().call(),
              blockTime:    await rckToken.methods.getTimeStamp().call(),

              lastBlocking: await rckToken.methods.lastBlockingOf(accounts[0]).call(),

              apy: await rckToken.methods.getAPY().call() / 1000 * 100
          }


          setrckContractInfo(contractInfo);
          console.log(contractInfo);


          let contractBalances = await rckToken.methods.balancesOf(accounts[0]).call();
          setrckContractBalances(contractBalances);
          console.log(contractBalances);



          //  fetching balance of Testtoken and storing in state
          let access = await rckToken.methods
            .getAccess(accounts[0])
            .call();

          setrckAccess(access);
          console.log(access);

          //  fetching balance of Testtoken and storing in state
          let price = await rckToken.methods
            .getPrice()
            .call();

          setrckPrice(price);
          console.log(price);



          //  fetching balance of Testtoken and storing in state
          let aw = await rckToken.methods
            .getAccessWallets()
            .call();

          console.log(aw);

          // TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF

          let friendExists = false;
          let friendReal = false;



          let selfExists = false;


          //LIVEFIX
/*
          let selfExists = await rckToken.methods
            .memberExists(accounts[0])
            .call();

          console.log("account check:", selfExists);
*/
          if ( selfExists ) {
            friendAddress = await rckToken.methods
              .getPromotedBy(accounts[0])
              .call();
            friendReal = true;
            friendExists = true;

          } else {

            if ( web3.utils.isAddress(friendAddress)) {
              friendReal = true;
              friendExists = await rckToken.methods
                .memberExists(friendAddress)
                .call();
            } else {
              console.log("friendAddress not valid");
            }
          }

          setrckFriendReal(friendReal);
          setrckFriendExists(friendExists);

          console.log(friendExists);

          setUriInvite( [ uriParts[0] ,  uriParts[1] ,  uriParts[2] + (uriParts[2][-1]==='?' ? "" : '?') , crpt(accounts[0],true) ].join("/") );


          setLivePebbles(false);

          if (!tickerEnabled)
            fetchTickerFromBlockchain({ last: [], first: true });

          fetchSubsFromBlockchain({});

      }

      //removing loader
      setLoader(false);
    } else if (!window.web3) {

      console.log("no ethereum available");
      setAppStatus(false);

      setAccount('Metamask is not detected');
      setLoader(false);
    } else {

      setAccount('Miau');
      setLoader(false);
    }
  };



  const inputBlockHandler = (received) => {
    setInputBlock(received);
  };

  const rckBlock = async () => {
    if (!appStatus) {
    } else {

      if (!inputBlock || parseInt(inputBlock) < 1) { return; }

      setLoader(true);
      rckTokenContract.methods
        .enblock(account, inputBlock)
        .send({
          from: account,
          value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
          gasLimit: window.web3.utils.toHex(200000),
          gasPrice: window.web3.utils.toHex(window.web3.utils.toWei('40', 'gwei'))
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };




  const inputUnBlockHandler = (received) => {
    setInputUnBlock(received);
  };

  const rckUnBlock = async () => {
    if (!appStatus) {
    } else {

      if (!inputUnBlock || parseInt(inputUnBlock) < 1) { return; }

      setLoader(true);
      rckTokenContract.methods
        .unblock(account, inputUnBlock)
        .send({
          from: account,
          value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
          gasLimit: window.web3.utils.toHex(200000),
          gasPrice: window.web3.utils.toHex(window.web3.utils.toWei('40', 'gwei'))
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };



  const inputDigHandler = (received) => {
    setInputDig(received);
  };

  const rckDig = async ( ) => {

    if (!appStatus) { return; }

//    console.log(inputDig, friendAddress);
    console.log("inputdig", inputDig);

    if (!inputDig || parseInt(inputDig) < 1) { return; }

    let e = ( inputDig *  rckPrice / 1000).toString();
    console.log("price",{
      "price": rckPrice,
      "price/1000": rckPrice / 1000,
      "amount": inputDig,
      "e": e
    });

    if (friendAddress.length < 40 ) { alert('Find a friend who invites you'); return;}

    setLoader(true);
    rckTokenContract.methods
      .dig( inputDig , friendAddress )
      .send({
        from: account,
        value: window.web3.utils.toHex(window.web3.utils.toWei( e, 'ether')),
        gasLimit: window.web3.utils.toHex(600000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(window.web3.utils.toWei('40', 'gwei'))
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();

        fetchSubsFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };


  const rckPayOut = async ( ) => {

    if (!appStatus) { return; }

    setLoader(true);
    rckTokenContract.methods
      .payOut()
      .send({
        from: account,
        value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
        gasLimit: window.web3.utils.toHex(340000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(window.web3.utils.toWei('40', 'gwei'))
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };





  let metamaskURI = "https://metamask.io/download/";
  let braveURI = "https://brave.com/";
  let metamaskDeepURI = "https://metamask.app.link/dapp/" + window.location.href;



  if ( !appStatus ) {

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))

      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon} alt="logo" className={classes.icon} />
            <h1> please use mobile Metamask compatible Wallet</h1>
            <p>{account}</p>
            <p>Visit <a href={metamaskURI} target="_blank" rel="noopener noreferrer">Metamask</a> first</p>
            <p></p>
            <h1> open this Link in the Metamask's own Browser</h1>
            <p></p>
            <p>Visit <a href={metamaskDeepURI} rel="noopener noreferrer">DAPP in Metamask</a> then</p>
          </div>
        </div>
      )
   else
      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon2} alt="logo" className={classes.icon} />
            <h1> please install Brave-Browser on Desktop or any Metamask compatible Wallet-Plugin </h1>
            <p>{account}</p>
            <p>Visit <a href={braveURI}>Brave</a> first</p>
          </div>
        </div>
      )
  }



  if ( network.id != chainId && chainChangeCounter > 10)

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <img src={icon} alt="logo" className={classes.icon} />
          <h1> wrong Network </h1>
          <p>{network.id}</p>
          <p></p>
          <p>Switch Network to <b>Rinkeby</b> (on Metamask Mobile via Button "...")</p>
        </div>
      </div>
    )


  const changePage = () => {
    if (page === 1) {
      setPage(2);
    } else if (page === 2) {
      setPage(1);
    }
  };

  if ( /origin/i.test(  window.location.href  ))
    return (
      <Origin/>
    );

  return (
    <div className={classes.Grid}>
      {loader ? <div className={classes.curtain}></div> : null}
      <div className={classes.loader}></div>
      <div className={classes.Child}>
        <Navigation changePage={changePage} account={account}   access={rckAccess}>
        <div>
          <Balance
            account={account}
            network={network}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            Dig={rckDig}
            price={rckPrice / 1000}
            contract={rckTokenContract}
            friendAddress={friendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            uriParts={uriParts}
          />
        </div>
        <div className={classes.Dig}>
          <Dig
            page={page}
            Dig={rckDig}
            amount={inputDig}
            contract={rckTokenContract}
            friendAddress={friendAddress}
            friendExists={rckFriendExists}
            price={rckPrice / 1000}
            actionHandler={rckDig}
            inputHandler={inputDigHandler}
            userBalance={userBalance}
            gasPrice={gasPrice}
          />
        </div>
        <div className={classes.inStore}>
          <Block
            page={page}
            actionHandler={rckBlock}
            inputHandler={inputBlockHandler}
            info={rckContractInfo}
            balances={rckContractBalances}
          />
        </div>
        <div className={classes.outStore}>
          <UnBlock
            page={page}
            actionHandler={rckUnBlock}
            inputHandler={inputUnBlockHandler}
            info={rckContractInfo}
            balances={rckContractBalances}
          />
        </div>
        <div className={classes.pay}>
          <Pay
            page={page}
            info={rckContractInfo}
            balances={rckContractBalances}
            contract={rckTokenContract}
            actionHandler={rckPayOut}
          />
        </div>
        <div className={classes.info}>
          <Info
            account={account}
            network={network}
            gasPrice={gasPrice}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            Dig={rckDig}
            price={rckPrice / 1000}
            contract={rckTokenContract}
            friendAddress={friendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            subs={rckSubs}
          />
        </div>
         </Navigation>
      </div>
    </div>
     );

}

export default App;
