exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*\nhtml, body {\n  height: 100%;\n  margin: 0;\n  overflow: hidden;\n}\n\nbackground: #000;\n*/\n\n.Origin_logo__1lObo {\n  position: absolute;\n  color: hsl(136, 100%, 85%);\n  text-shadow:\n    0 0 .5em #fff,\n    0 0 .5em currentColor; \n}\n\n.Origin_base__MwOwB {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.Origin_rain__3juJ3 {\n  display: flex;\n}\n.Origin_rain__3juJ3 p {\n  line-height: 1;\n}\n.Origin_rain__3juJ3 span {\n  display: block;\n  width: 2vmax;\n  height: 2vmax;\n  font-size: 2vmax;\n  color: #9bff9b11;\n  text-align: center;\n  font-family: \"Helvetica Neue\", Helvetica, sans-serif;\n}\n", ""]);

// exports
exports.locals = {
	"logo": "Origin_logo__1lObo",
	"base": "Origin_base__MwOwB",
	"rain": "Origin_rain__3juJ3"
};