import React, { useState } from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import rockIcon from '../assets/rock.png';
import rightIcon from '../assets/rightIcon.png';


const Block = (props) => {
  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {

    let i = Math.round(event.target.value)
    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(i);
    }

    event.preventDefault();
    setInputValue(event.target.value);
    props.inputHandler(event.target.value);
  };

  const goMax = () => {
    setInputValue( props.balances[0] );
//    props.inputHandler(props.userBalance);
  };


  return (
    <div className={classes.Info}>


      <h5 onClick={goMax} className={classes.goMax}>
       unrocked: <b>{props.balances ? props.balances[0] : "n/a"}</b> ROCKs
        &nbsp;
        <button
          className={classes.allButton}
           onClick={goMax}
        >
          <p>All</p>
        </button>
        </h5>




      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.digButton}
        disabled={ !props.balances || props.balances[0] < 1  }
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>Rock</p>
      </button>

      <div className={classes.boxHigh} >
        <p> rocking: <b>{props.balances ? props.balances[1] : "n/a" }</b> ROCks</p>
      </div>


      <p><br/></p>
      <img alt="Rock" src={rockIcon} width="50px"/>
      <div className={classes.box}>
      <p>Principle of Rocking</p>
      <div><p>
        You decide to declare some of Your Rocks as "rocked". You will not be able to transfer these rocked Rocks until you unrock them.
        For the period of "rocking the Rocks" you'll get rewarded continuously. You're free to rock or unrock whenever you like. And don't forget to pay out your rewards.
        <br/>
        <br/>
        When rocking, all of your Rocks are kept in your Wallet. No Rock is being transfered, swapped, burned or changes the ownership.
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Block;
