import React, { useState } from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';




const Dig = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {
    event.preventDefault();

    let i = parseInt(event.target.value);

    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(i);
    }
    props.inputHandler(i);

  };

  const goMax = () => {
    const plus = 5; // %
    const gas = 600000; //439182
    const costs = props.gasPrice * gas * ((100 + plus)/100) / 1000000000;

    let i = Math.floor( (props.userBalance - costs) / props.price);

    if (i < 1) { i = 0; }

    setInputValue(i);

    props.inputHandler(i);
//    props.inputHandler(props.userBalance);
  };


  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);


  return (
    <div className={classes.Info}>

      <h5 onClick={goMax} className={classes.goMax}>
        My balance: { Math.floor(props.userBalance * 100)/100 } ETH
        &nbsp;
        <button
          className={classes.allButton}
          disabled={!props.friendExists}
          onClick={goMax}
        >
          <p>All</p>
        </button>

      </h5>

      <div className={classes.boxHigh}>
        <p>Price:  {props.price + " ETH"}</p>
        <p>Amount: {props.amount === "" ? "please enter amount" : props.amount + " ROCKs"}</p>
      </div>



      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.digButton}
        disabled={!props.friendExists}
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>Dig</p>
      </button>


      <div className={classes.box}>

        <p>Rock Brother: <b>{props.friendAddress === '' ? "is missing" : dottedFriendAddress }</b>{ props.friendExists ? "" : "(has not digged ROCKs yet)"}</p>
      </div>

      <p><br/></p>
      <div className={classes.box}>
      <p>Digging helps you start Rocking</p>
      <div><p>
        You start with digging some Rocks. Any Rock is ERC20 compatible. Feel free to transfer Rocks to your friends. But it is also a good idea to
        rock your Rocks here and earn Ethereum.
        <br/>
        <br/>
        { props.friendExists ? "" : "If your Rock Brother - the one who invited you - has not digged Rocks yet, you cannot start. Sharing is caring."}
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};

export default Dig;
