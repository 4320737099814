exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".App_Grid__1lAyL {\n  display: grid;\n  grid-gap: 1px;\n  grid-template-columns: auto auto auto auto auto;\n  grid-template-rows: auto auto auto;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,\n    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n  font-weight: 400;\n\n}\n\n\n\n.App_icon__1BQ3R {\n  height: 7em;\n  margin-top: 2em;\n  opacity: 0.8;\n}\n\n\n.App_Child__q4qwq {\n  min-width: 400px;\n  min-height: 600px;\n  max-width: 600px;\n\n  background: linear-gradient(\n      16deg,\n      rgb(56, 30, 11) 0%,\n      rgb(73, 37, 10) 42%,\n      rgb(95, 45, 8) 100%\n    ),\n    linear-gradient(to right, rgb(99, 62, 37), rgb(70, 31, 11));\n  border: 0.3em solid rgba(139, 60, 23, 0.514);\n  background-repeat: no-repeat;\n  background-origin: padding-box, border-box;\n  border-radius: 2.5em;\n  grid-column: 3;\n  grid-row: 2;\n  text-align: center;\n  color: white;\n  box-shadow: 0px 0px 800px 20px rgba(99, 75, 19, 0.44);\n}\n\n@media only screen and (max-device-width: 400px) {\n  .App_Child__q4qwq {\n    min-width: 0;\n  }\n}\n\n.App_curtain__14JNj {\n  background-color: rgba(0, 0, 0, 0.466);\n  position: static;\n  height: 99em;\n  width: auto;\n  grid-column-start: 1;\n  grid-column-end: 6;\n  z-index: 2;\n  -webkit-filter: blur(8px);\n          filter: blur(8px);\n  -webkit-backdrop-filter: blur(1.5em);\n  backdrop-filter: blur(1.5em);\n}\n\n\na {\n  color: rgb(201, 157, 119);\n  text-decoration: none;\n}\n", ""]);

// exports
exports.locals = {
	"Grid": "App_Grid__1lAyL",
	"icon": "App_icon__1BQ3R",
	"Child": "App_Child__q4qwq",
	"curtain": "App_curtain__14JNj"
};